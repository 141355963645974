<template>
    <div class="SysInDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="80px" width="auto" size="small" inline>
                <el-form-item label="机构名称">
                    <el-input readonly v-model="form.deptName" />
                </el-form-item>
                <el-form-item v-if="auditDetail">
                    <el-button type="primary" @click="handleAudit" v-if="auditDetail" size="small">审核详情</el-button>
                    <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.purchase.sysIn.review'" />
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="160" />
                <el-table-column prop="sku.bars" label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.type" label="类型" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | goodsType }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="100" />
                <el-table-column prop="customBar" label="自编码" width="100" />
                <el-table-column prop="sku.specs" label="规格" width="120" />
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="120" />
                <el-table-column prop="sku.brand" label="品牌" width="120" />
                <el-table-column prop="stock" label="库存" width="100" />
                <el-table-column prop="wholeSalePrice" label="批发价" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.wholeSalePrice }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="retailPrice" label="零售价" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.retailPrice }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination @pageChange="pageChange" :total="page.total" :default-limit="page.limit"></ef-pagination>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import PickupReturnAudit from 'components/PickupReturnAudit';
import UrlUtils from 'js/UrlUtils';
import EfPagination from 'components/EfPagination';

export default {
    name: 'SysInDetail',
    props: ['form'],
    components: { EfPagination, PickupReturnAudit },
    data() {
        return {
            baseUrl: '/purchase/sysIn',
            auditDetail: false,
            url: {
                page: '/purchase/sysIn/detail/page',
            },
            audit: {
                code: '',
                bizCode: '',
                auditDate: '',
                advice: '',
                remark: '',
            },
            page: {
                offset: 0,
                limit: 50,
                total: 0,
            },
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
        UrlUtils.QueryRemote(this, `${this.baseUrl}/reviewListData/` + this.form.code, (rst) => {
            if (rst.reviewList.length > 0) {
                this.auditDetail = true;
            }
        });
    },
    filters: {
        m(m) {
            MoneyUtils.formatMoney(m);
        },
    },
    methods: {
        pageChange(page, limit) {
            this.page.page = page;
            this.page.limit = limit;
            this.handleQuery();
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl, this.form.stockStatus);
        },
        handleQuery() {
            const _this = this;
            const params = {
                params: {
                    bizCode: _this.form.code,
                    page: this.page.page,
                    limit: this.page.limit,
                },
            };
            Util.queryTable(_this, _this.url.page, params, (data) => {
                this.page.total = data.count;
                const dt = data.data;
                dt.forEach((v, i) => {
                    v.retailPrice = MoneyUtils.moneyToYuan(v.retailPrice);
                    v.wholeSalePrice = MoneyUtils.moneyToYuan(v.wholeSalePrice);
                });
                this.tableData = dt;
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
